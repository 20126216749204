.bottom-sheet {
  
  position: absolute;
  
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 100%;
  
  &.active {
    top: 60%;
  }
  
  &.full {
    top: 0px;
  }

  @screen md {
    top: 20px;
    left: 20px;
    right: auto;
    bottom: 20px;
    width: 400px;
    
    &.active {
      top: 20px;
    }
  }
  
  transition: top .5s ease;
  
  padding: 20px;
  border-radius: 10px;
  
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);

  .bottom-sheet-body {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    
    padding: 20px;

    /* Allow scrolling within this frame, but hide the scrollbars */
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  button.close {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: black;
    position: absolute;
    background: #EEEDF0;
    right: 10px;
    top: 10px;
    
    @screen md {
      display: none;
    }
  }
  
  .bottom-sheet-handle {
    border-radius: 3px;
    background: #ddd;
    height: 5px;
    width: 50px;
    position: absolute;
    left: 0;
    right: 0;
    top: 9px;
    margin-left: auto;
    margin-right: auto;
    
    @screen md {
      display: none;
    }
  }
  
}