@import "tailwindcss/base";
@import "tailwindcss/components";

/*
 * Jumpstart Pro default styles
 * Remove these if you'd like to use TailwindUI which expects a clean slate
*/
/*
@import "components/base";
@import "components/actiontext";
@import "components/alert";
@import "components/animation";
@import "components/announcements";
@import "components/avatars";
*/
@import "components/buttons";
/*
@import "components/code";
@import "components/connected_accounts";
@import "components/direct_uploads";
@import "components/docs";
*/
@import "components/fonts";
@import "components/forms";
@import "components/modal";
/*
@import "components/iframe";
*/
@import "components/nav";
/*
@import "components/notifications";
@import "components/pagination";
@import "components/strada";
@import "components/tabs";
@import "components/trix";
*/
@import "components/typography";
/*
@import "components/util";
@import "components/braintree";
*/

@import "tailwindcss/utilities";

@import "flatpickr/dist/flatpickr.css";
@import "tippy.js/dist/tippy.css";
@import "tom-select/dist/css/tom-select.css";

/* CLEANCAR CUSTOM START */

@import "components/bottom_sheets";
@import "components/panels";
@import "components/payment_buttons";
@import "components/placeholders";
@import "components/preview";
@import "components/split_views";
@import "components/scrollbars";
@import "components/stacked_list_navs";
@import "components/star_ratings";
@import "components/ul_lists";

@import "photoswipe/dist/photoswipe.css";

/* CLEANCAR CUSTOM END */