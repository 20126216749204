@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 400;
  src: url('/Hind-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  src: url('/Hind-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 600;
  src: url('/Hind-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 700;
  src: url('/Hind-Bold.ttf') format('truetype');
}

.font-hind {
  font-family: 'Hind';
}
