.stacked_list {
  @apply bg-white shadow-sm overflow-hidden rounded-sm;

  .section {
    @apply bg-gray-50 px-4 py-2 text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider;
  };

  ul {
    @apply divide-y divide-gray-200;

    li {
      @apply px-4 py-4 hover:bg-gray-50;
      list-style: none;

      form {
        @apply w-full;
      };

      a, button {
        @apply flex items-center w-full;
      };

      .icon {
        @apply h-6 w-6 rounded-full mr-3 text-gray-400 text-center;
      };

      .text {
        @apply text-sm font-medium text-gray-900 grow;
      };

      .arrow {
        @apply text-gray-400 h-2 w-2 mb-2;
      };
    };
  };
};
