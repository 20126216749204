.font-display {
  @apply text-5xl font-bold leading-tight tracking-tight;
}


h1, .h1 {
  @apply text-2xl font-semibold leading-tight tracking-tight text-branding-dunkelblau-2;
}

h2, .h2 {
  @apply font-semibold leading-tight text-branding-dunkelblau-2;
  font-size: 21px;
}

h3, .h3 {
  @apply font-semibold leading-normal text-branding-dunkelblau-2;
  font-size: 18px;
}

h4, .h4 {
  @apply text-xl font-semibold leading-normal text-branding-dunkelblau-2;
}

h5, .h5 {
  @apply text-lg font-semibold leading-normal text-branding-dunkelblau-2;
}

h6, .h6 {
  @apply font-semibold text-branding-dunkelblau-2;
}

.link {
  @apply text-branding-dunkelblau-1 underline;

  &:hover,
  &:focus {
    @apply underline;
  }
}
