.split_view {
  @apply w-full flex;

  .split_left {
    @apply max-w-md p-2;
  };
  .split_divider {
    @apply mx-4;
  };
  .split_right {
    @apply p-2 grow;
  };
};
